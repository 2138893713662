export const PageMixin = {
	props: {
		isSubPage: {
			type : Boolean,
			default : false,
		},
	},
	data : function() {
		return {
			inputSearch : "",
			filters: {},
			filtersLabel: {},
			
		}
	},
	computed: {
		isDialogOpen: function () {
			return this.$store.getters["pageComponents/isDialogOpen"];
		},
	},
	methods: {
		toggleActionMenu(event) {
			this.$refs.actionMenu.toggle(event);
		},
		openPageDialog: function (pageData, dialog) {
			this.$store.commit("pageComponents/setRightDrawer", false);
			this.$store.commit("pageComponents/setDialogProps", dialog);
			this.$store.dispatch('pageComponents/openPageDialog', pageData);
		},
		openPageDrawer: function (pageData, dialog) {
			this.$store.commit("pageComponents/setPageDialog", false);
			this.$store.commit("pageComponents/setDrawerProps", dialog);
			this.$store.dispatch('pageComponents/openPageDrawer', pageData);
		},
		closeDialogs: function () {
			this.$store.commit("pageComponents/setRightDrawer", false);
			this.$store.commit("pageComponents/setPageDialog", false);
		},
		setPageTitle: function (title, pagename) {
			document.title = title;
		},
		flashMsg: function (detail, title, severity) {
			title = title || "";
			severity = severity || "success";
			if(detail){
				this.$toast.add({severity, summary: title, detail, life: 3000});
			}
		},
		navigateTo: function (path) {
			if(this.$route.path !== path){
				this.$router.push(path)
			}
		},
		showPageRequestError: function (request) {
			let defaultMsg = "Error processing request!";
			let errorMsgs = [defaultMsg];
			if (request.response) {
				let error = request.response.data;
				if(Array.isArray(error)){
					errorMsgs = error;
				}
				else if(typeof(error) === 'object'){
					errorMsgs = Object.values(error);
				}
				else{
					errorMsgs = [error.toString()]
				}
			}
			this.$store.dispatch('pageComponents/showPageErrors', errorMsgs);
			
		},
		exportPageRecords: function (exportType, fileExt) {
			let url = this.apiUrl;
			let queryParam = {
				export: exportType
			}
			let exportUrl = this.$utils.setApiPath(url, queryParam);
			let fileName = new Date().toISOString().slice(0, 10) + '-' + this.pageName + "-report." + fileExt;
			this.$api.download(exportUrl).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
			},
			(response) => {
				console.error(response);
				alert("Unable to download file")
			});
			
		},
		setFilter: function(options, fieldName) {
			if(Array.isArray(options)){
				let selectedOptions = this.filters[fieldName];
				if(Array.isArray(selectedOptions)){
					let labels = [];
					for (var i in options) {
						if (selectedOptions.indexOf(options[i].value) > -1) {
							labels.push(options[i].label);
						}
					}
					this.filtersLabel[fieldName] = labels;
				}
				else if(selectedOptions.label){
					this.filtersLabel[fieldName] = selectedOptions.label;
				}
				else{
					let option = options.find(item => item.value === selectedOptions);
					this.filtersLabel[fieldName] = option.label;
				}
			}
			else if(options.label){
				this.filters[fieldName] = options.value;
				this.filtersLabel[fieldName] = options.label;
			}
			else{
				this.filters[fieldName] = options;
				this.filtersLabel[fieldName] = options;
			}
		},
		normalizedFormData: function(){
			let payload = this.formData || this.arrFormData;
			if(Array.isArray(payload)){
				payload.forEach(function(obj){
					Object.keys(obj).forEach(function(key){
						if(Array.isArray(obj[key])){
							obj[key] = obj[key].toString()
						}
					});
				})
			}
			else{
				Object.keys(payload).forEach(function(key){
					if(Array.isArray(payload[key])){
						payload[key] = payload[key].toString()
					}
				});
			}
			return payload;
		},
		mapOptionField: function(response, fieldname){
			if(this.formData){
				let currentValue = this.formData[fieldname] ?? null;
				if(currentValue){
					if(Array.isArray(currentValue)){
						let mapSelectedOptions = [];
						let mapSelectedOptionsValue = [];
						currentValue.forEach( val =>{
							let option = response.find(v => v.value == val);
							mapSelectedOptions.push(option);
							mapSelectedOptionsValue.push(option.value)
						});
						this.formData[fieldname] = mapSelectedOptions; // update the select label
						this.formData[fieldname] = mapSelectedOptionsValue; // this will emit the value.
					}
					else{
						let mapSelectedOption = response.find(v => v.value == currentValue);
						this.formData[fieldname] = mapSelectedOption; // update the select label
						this.formData[fieldname] = mapSelectedOption.value;  // this will emit the value.
					}
				}
			}
		},
		setFilterRange(filterName){
			this.filters[filterName].min = this[filterName][0];
			this.filters[filterName].max = this[filterName][1];
		},
		updateDateRangeFilter(filterName){
			let [startDate, endDate] = this[filterName];
			let filter = this.filters[filterName];
			if(startDate && endDate) {
				filter.from = this.$utils.formatDate(startDate);
				filter.to = this.$utils.formatDate(endDate);
			}
		},
		clearDateRangeFilter(filterName){
			this[filterName] = []; //reset vue data model
			this.filters[filterName] = {from: '', to: ''}; //reset date filters
		},
		removeFilterItem(filterName, value){
			let values = this.filters[filterName];
			if(values){
				let index = values.indexOf(value);
				this.filters[filterName].splice(index, 1);
			}

			let labels = this.filtersLabel[filterName];
			if(labels){
				let index = labels.indexOf(value);
				this.filtersLabel[filterName].splice(index, 1);
			}
			
		},
		pageCurrentRecord: function(page, field){
			let record = this.$store.getters[`${page}/currentRecord`];
			if(field && record){
				return record[field]??null;
			}
			return record;
		},
	},
	watch: {
		$route: {
            immediate: true,
            handler(to, from) {
				if(to !== from ) {
					let pageTitle = this.pageTitle;
					if(pageTitle && this.routeName == this.$route.name){
						document.title = pageTitle;
					}
				}
            }
        },
	},
}